export default {
	state: () => ({
		dealConditions: null,
		pinnedTokens: [],
		tonTokens: [],
		userTokens: [],
		tokenLabels: [],
		dexWallet: null,
		dexWalletVersion: null,
		sendToken: null,
		receiveToken: null,
		sendAmount: 0,
		receiveAmount: 0,
		swapMode: 'default',
		stakingPool: null,
		tokenPagination: {
			currentPage: 0,
			totalPages: 0
		},
		tokensByLabel: {},
		proofVerification: null,
		payloadId: null,
		areTokensLoaded: false,
		tokensPopupState: {
			currentPageByTab: {
				all: 1,
				new: 1,
				cashback: 1,
				contest: 1,
			},
			hasMoreTokensByTab: {
				all: true,
				new: true,
				cashback: true,
				contest: true,
			},
			activeTab: 'all'
		}
	}),
	mutations: {
		SET_TOKENS_POPUP_STATE(state, payload) {
			state.tokensPopupState = {
				...state.tokensPopupState,
				...payload
			};
		},
		SAVE_TOKENS_BY_LABEL(state, { labelId, tokens }) {
			state.tokensByLabel = {
				...state.tokensByLabel,
				[labelId]: tokens,
			};
		},
		SAVE_TOKENS_OPTIONS(state, options) {
			state.tokenPagination.currentPage = options.current_page;
			state.tokenPagination.totalPages = options.total_pages;
		},
		SAVE_DEAL_CONDITIONS(state, item) {
			state.dealConditions = item
		},
		SAVE_TON_TOKENS(state, item) {
			let newData = item.map(token => ({
				...token,
				balance: token.balance || 0
			}));
			state.tonTokens = newData;
			state.areTokensLoaded = true;
		},
		SAVE_TOKEN_LABELS(state, item) {
			state.tokenLabels = item
		},
		SAVE_PAYLOAD_ID(state, item) {
			state.payloadId = item
		},
		SAVE_PROOF_VERIFICATION(state, item) {
			state.proofVerification = item
		},
		SAVE_PINNED_TOKENS(state, item) {
			state.pinnedTokens = item
		},
		SAVE_USER_TOKENS(state, item) {
			state.userTokens = item
		},
		SAVE_DEX_WALLET(state, item) {
			state.dexWallet = item
		},
		SAVE_SEND_TOKEN(state, item) {
			state.sendToken = item
		},
		SAVE_RECEIVE_TOKEN(state, item) {
			state.receiveToken = item
		},
		SAVE_SEND_AMOUNT(state, item) {
			state.sendAmount = item
		},
		SAVE_RECEIVE_AMOUNT(state, item) {
			state.receiveAmount = item
		},
		SAVE_SWAP_MODE(state, item) {
			state.swapMode = item
		},
		CLEAR_STORE(state) {
			state.dealConditions = null;
			state.dexWallet = null;
			state.dexWalletVersion = null;
			state.sendToken = null;
			state.receiveToken = null;
			state.sendAmount = 0;
			state.receiveAmount = 0;
			state.swapMode = null;
			state.stakingPool = null;
			state.areTokensLoaded = false;
		},
		SAVE_DEX_WALLET_VERSION(state, item) {
			state.dexWalletVersion = item
		},
		SAVE_STAKING_POOL(state, item) {
			state.stakingPool = item
		},
	},
	actions: {
		DEX_TOKENS_BY_LABEL({ commit }, payload) {
			commit('SAVE_TOKENS_BY_LABEL', payload);
		},
		DEX_TOKENS_OPTIONS({commit}, options) {
			commit('SAVE_TOKENS_OPTIONS', options);
		},
		DEX_DEAL_CONDITIONS({commit}, item) {
			commit('SAVE_DEAL_CONDITIONS', item)
		},
		DEX_TON_TOKENS({commit}, item) {
			commit('SAVE_TON_TOKENS', item)
		},
		DEX_TOKEN_LABELS({commit}, item) {
			commit('SAVE_TOKEN_LABELS', item)
		},
		DEX_PROOF_VERIFICATION({commit}, item) {
			commit('SAVE_PROOF_VERIFICATION', item)
		},
		DEX_PAYLOAD_ID({commit}, item) {
			commit('SAVE_PAYLOAD_ID', item)
		},
		DEX_PINNED_TOKENS({commit}, item) {
			commit('SAVE_PINNED_TOKENS', item)
		},
		DEX_USER_TOKENS({commit}, item) {
			commit('SAVE_USER_TOKENS', item)
		},
		DEX_WALLET({commit}, item) {
			commit('SAVE_DEX_WALLET', item)
		},
		DEX_SEND_TOKEN({commit}, item) {
			commit('SAVE_SEND_TOKEN', item)
		},
		DEX_RECEIVE_TOKEN({commit}, item) {
			commit('SAVE_RECEIVE_TOKEN', item)
		},
		DEX_SEND_AMOUNT({commit}, item) {
			commit('SAVE_SEND_AMOUNT', item)
		},
		DEX_RECEIVE_AMOUNT({commit}, item) {
			commit('SAVE_RECEIVE_AMOUNT', item)
		},
		CHANGE_SWAP_MODE({commit}, item) {
			commit('SAVE_SWAP_MODE', item)
		},
		CLEAR_DEX_STORE({commit}) {
			commit('CLEAR_STORE')
		},
		DEX_WALLET_VERSION({ commit }, item) {
			commit('SAVE_DEX_WALLET_VERSION', item)
		},
		DEX_STAKING_POOL({commit}, item) {
			commit('SAVE_STAKING_POOL', item)
		}
	},
	getters: {
		GET_TOKENS_POPUP_STATE: state => {
			return state.tokensPopupState
		},
		GET_TOKENS_BY_LABEL: (state) => (labelId) => {
			return state.tokensByLabel[labelId] || [];
		},
		GET_DEAL_CONDITIONS: state => {
			return state.dealConditions
		},
		GET_PROOF_VERIFICATION: state => {
			return state.proofVerification
		},
		GET_PAYLOAD_ID: state => {
			return state.payloadId
		},
		GET_TON_TOKENS: state => {
			return state.tonTokens
		},
		GET_TOKEN_LABELS: state => {
			return state.tokenLabels
		},
		GET_PINNED_TOKENS: state => {
			return state.pinnedTokens
		},
		GET_USER_TOKENS: state => {
			return state.userTokens
		},
		GET_DEX_WALLET: state => {
			return state.dexWallet
		},
		GET_SEND_TOKEN: state => {
			return state.sendToken
		},
		GET_RECEIVE_TOKEN: state => {
			return state.receiveToken
		},
		GET_SEND_AMOUNT: state => {
			return state.sendAmount
		},
		GET_RECEIVE_AMOUNT: state => {
			return state.receiveAmount
		},
		GET_SWAP_MODE: state => {
			return state.swapMode
		},
		GET_DEX_WALLET_VERSION: state => {
			return state.dexWalletVersion
		},
		GET_STAKING_POOL: state => {
			return state.stakingPool
		},
		GET_TOKENS_LOADED: state => {
			return state.areTokensLoaded;
		}
	}
}