export default {
  state: () => ({
    claimHistory: null,
    claimInfo: null,
    activeFilter: 'all',
    claimTokenDetails: [],
    claimHistoryTokenDetails: []
  }),
  mutations: {
    SET_CLAIM_HISTORY_TOKEN_DETAILS(state, value) {
      state.claimHistoryTokenDetails = value;
    },
    SET_CLAIM_TOKEN_DETAILS(state, value) {
      state.claimTokenDetails = value;
    },
    SET_CLAIM_HISTORY(state, value) {
      state.claimHistory = value;
    },
    SET_CLAIM_INFO(state, value) {
      state.claimInfo = value;
    },
    SET_CLAIM_FILTER(state, value) {
      state.activeFilter = value
    },
  },
  actions: {
    CLAIM_HISTORY_TOKEN_DETAILS({ commit}, item) {
      commit('SET_CLAIM_HISTORY_TOKEN_DETAILS', item);
    },
    CLAIM_TOKEN_DETAILS({ commit }, details) {
      commit('SET_CLAIM_TOKEN_DETAILS', details);
    },
    CLAIM_HISTORY({ commit }, item) {
      commit('SET_CLAIM_HISTORY', item);
    },
    CLAIM_INFO({ commit }, item) {
      commit('SET_CLAIM_INFO', item);
    },
    CLAIM_FILTER({commit}, item) {
      commit('SET_CLAIM_FILTER', item)
    },
  },
  getters: {
    GET_CLAIM_HISTORY_TOKEN_DETAILS: (state) => {
      return state.claimHistoryTokenDetails;
    },
    GET_CLAIM_TOKEN_DETAILS: (state) => {
      return state.claimTokenDetails;
    },
    GET_CLAIM_HISTORY: (state) => {
      return state.claimHistory;
    },
    GET_CLAIM_INFO: (state) => {
      return state.claimInfo;
    },
    GET_CLAIM_FILTER: (state) => {
      return state.activeFilter
    },
  },
};