<template>
  <div class="app-wrapper" :class="{ dex_wrapper: getRouteName === 'Dex' }">
    <AppHeader :ton-connect-ui="tonConnectUi" />
    <div class="app-wrapper__flex">
      <div class="sidebar">
        <Sidebar />
      </div>
      <router-view :ton-connect-ui="tonConnectUi" @update-wallet-info="updateWalletInfo" />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import Sidebar from '@/components/Sidebar.vue';
import { toUserFriendlyAddress } from '@tonconnect/ui';
import { pinnedTokens } from '@/helpers/dex/pinnedTokens';
import { mapActions, mapGetters } from 'vuex';
import tonConnectMixin from '@/mixins/tonConnectMixin';
import AppFooter from '@/components/AppFooter.vue';
import { isInsideWalletBrowser } from '@/helpers/dex/embedded-wallets.js';
import { tracking } from '@/tracking';
import { Events } from '@/tracking/events.js';
import { Address } from '@ton/core';
import { tonApiService, tokenService } from '@/api/coffeeApi/services';
import TokenService from "@/api/coffeeApi/services/tokenService.js";

export default {
	name: 'AppWrapper',
	mixins: [tonConnectMixin],
	components: {
		AppFooter,
		Sidebar,
		AppHeader,
	},
	props: {
		tonConnectUi: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			unsubscribeConnect: null,
			loadInfoCount: 0,
			tonInfo: null,
			timeout: null,
		}
	},
	computed: {
		...mapGetters([
			'GET_TON_TOKENS',
			'GET_DEX_WALLET',
			'GET_PAYLOAD_ID',
			'GET_THEME',
      'GET_STAKE_NATIVE'
		]),
	},
	methods: {
		...mapActions([
			'DEX_TON_TOKENS',
			'DEX_PINNED_TOKENS',
			'DEX_USER_TOKENS',
			'DEX_TOKEN_LABELS',
			'DEX_WALLET',
			'DEX_SEND_TOKEN',
			'DEX_RECEIVE_TOKEN',
			'DEX_SEND_AMOUNT',
			'DEX_RECEIVE_AMOUNT',
			'CLEAR_DEX_STORE',
			'DEX_WALLET_VERSION',
			'DEX_PAYLOAD_ID',
			'DEX_PROOF_VERIFICATION',
			'ROUTE_CHANGED',
			'CLEAR_DEX_SETTINGS',
			'DEX_TOKENS_OPTIONS'
		]),
		updateWalletInfo() {
			this.getAccountInfo(this.GET_DEX_WALLET)
		},
		async getPinnedTokens() {
			try {
				let result = pinnedTokens()
				this.DEX_PINNED_TOKENS(result)
			} catch (err) {
				console.error(err)
			}
		},
		async getTokenLabels() {
			try {
				let res = await tokenService.getLabels()
				this.DEX_TOKEN_LABELS(res?.items)
			} catch(err) {
				console.error(err)
			}
		},
		async getTonTokens(retryCount = 0) {
			try {
				const toncoinAddress = "0:0000000000000000000000000000000000000000000000000000000000000000";
				const toncoinData = await TokenService.getTokenByAddress(toncoinAddress);
				
				let opts = {
					page: 1,
					size: 50
				};
				
				let res = await tokenService.getTokenListV2(opts);
				let tokens = res.items.map((item) => {
					item.type = item.address === toncoinAddress ? "native" : "jetton";
					item.address = item.address === toncoinAddress ? "native" : item.address;
					item.imported = false;
					return item;
				});
				
				if (!tokens.some(token => token.symbol === 'TON')) {
					tokens.unshift({ ...toncoinData, type: "native", address: "native", imported: false });
				}
				
				let tokensFromQuery = await this.loadTokensFromQueryParams() || [];
				tokens = this.mergeArrays(tokens, tokensFromQuery);
				
				let pinnedTokens = JSON.parse(localStorage.getItem('pinnedTokens')) || [];
				tokens = this.mergeArrays(tokens, pinnedTokens);
				
				let tokensWithImported = await this.checkImportTokens(tokens);
				
				this.DEX_TOKENS_OPTIONS({ "current_page": res.page, "total_pages": res.pages });
				this.DEX_TON_TOKENS(tokensWithImported);
				
				if (this.GET_DEX_WALLET !== null) {
					let userTokens = await this.getAccountInfo(this.GET_DEX_WALLET);
					tokensWithImported = this.mergeArrays(userTokens, tokensWithImported);
					this.DEX_TON_TOKENS(tokensWithImported);
				}
				
				this.checkQueryParams(this.GET_TON_TOKENS);
				this.checkTwaParams(this.GET_TON_TOKENS);
				
			} catch (err) {
				console.error(err);
				if (retryCount < 20) {
					setTimeout(() => {
						this.getTonTokens(retryCount + 1);
					}, 5000);
				}
			}
		},
		async loadTokensFromQueryParams() {
			const { ft, st } = this.$route.query;
			const addresses = [];
			
			if (ft && ft !== "TON") {
				addresses.push(ft);
			}
			if (st && st !== "TON") {
				addresses.push(st);
			}
			
			if (addresses.length > 0) {
				try {
					return await TokenService.getTokensByAddress(addresses);
					// this.checkQueryParams(tokens);
				} catch (error) {
					console.error("Ошибка при загрузке жетонов:", error);
				}
			}
		},
		async checkImportTokens(tokens) {
			let importedToken = JSON.parse(localStorage.getItem('importTokens'));
			if (importedToken) {
				importedToken.forEach((item) => {
					let findTokensItem = tokens.find((find) => find?.address === item?.address);
					if (!findTokensItem) {
						tokens.push(item);
					}
				});
			}
			return tokens;
		},
		mergeArrays(first, second) {
			return first.concat(second)
				.filter((obj, index, self) => {
					return index === self.findIndex((t) => t?.id === obj?.id);
				});
		},
		checkTwaParams(mergeTokens) {
			if (window.Telegram.WebApp.platform !== 'unknown') {
				let startParam = window.Telegram.WebApp?.initDataUnsafe?.start_param;
				
				if (window.Telegram.WebApp.disableVerticalSwipes) {
					window.Telegram.WebApp.disableVerticalSwipes();
				}
				
				if (startParam) {
					let params = startParam.split('_')
					
					const refParam = params.find((param, index) => param === 'ref' && index + 1 < params.length)
						? params[params.indexOf('ref') + 1] : null;
					
					
					const userRef = params.find((param, index) =>
						(param === 'r' || param === 'referral' || param === 'user_referral') && index + 1 < params.length
					)
						? params[params.indexOf('r') + 1]
						: null;
					
					
					if (refParam) {
						sessionStorage.setItem('referral_name', JSON.stringify(refParam));
					}
					
					if (userRef) {
						sessionStorage.setItem('user_referral', JSON.stringify(userRef));
					}
					
					if (params.length >= 4) {
						let ft = params[1].toUpperCase()
						let st = params[3].toUpperCase()
						let fa = params[5]
						
						let first = mergeTokens.find((item) => item.symbol === ft)
						let second = mergeTokens.find((item) => item.symbol === st)
						if (first) {
							this.DEX_SEND_TOKEN(first)
						}
						if (second) {
							this.DEX_RECEIVE_TOKEN(second)
						}
						if (fa) {
							this.DEX_SEND_AMOUNT(fa)
						}
					}
				}
			}
		},
    isAddress(value) {
      try {
        if (value === 'native') {
          return 'TON'
        }

        Address.parseFriendly(value);
        return true;
      } catch (error) {
        return false;
      }
    },
    getRouteName() {
      return this.$route.name;
    },
    toRawAddress(address) {
      try {
        if (address === 'native') {
          return 'TON'
        }

        const parsedAddress = Address.parseFriendly(address);
        return parsedAddress.address.toRawString();
      } catch (error) {
        return address;
      }
    },
    checkQueryParams(mergeTokens) {
      let route = this.$route;

      if (route.name === 'Stake') {
        let findToken = mergeTokens.find((item) => item.symbol === 'CES');
        if (findToken) {
          this.DEX_SEND_TOKEN(findToken);
        }
        return;
      }

      if (route.query?.ref) {
        sessionStorage.setItem('referral_name', JSON.stringify(route.query?.ref));
      }
      if (route.query?.referral ?? route.query?.r) {
        sessionStorage.setItem('user_referral', JSON.stringify(route.query?.referral ?? route.query?.r));
      }

      if (route.query?.ft && route.query?.st) {
        let first, second;

        const ftRawAddress = this.isAddress(route.query?.ft) ? this.toRawAddress(route.query?.ft) : null;
        const stRawAddress = this.isAddress(route.query?.st) ? this.toRawAddress(route.query?.st) : null;

        first = mergeTokens.find((item) => item.address === ftRawAddress) ||
          mergeTokens.find((item) => item.symbol === route.query?.ft);

        second = mergeTokens.find((item) => item.address === stRawAddress) ||
          mergeTokens.find((item) => item.symbol === route.query?.st);

        if (first) {
          this.DEX_SEND_TOKEN(first);
        }
        if (second) {
          this.DEX_RECEIVE_TOKEN(second);
        }

        setTimeout(() => {
          if (route.query?.fa > 0) {
            this.DEX_SEND_AMOUNT(Number(route.query?.fa));
          } else if (route.query?.sa > 0) {
            this.DEX_RECEIVE_AMOUNT(Number(route.query?.sa));
          }
        }, 10);

      } else if (route.query?.ft) {
        let first;

        const ftRawAddress = this.isAddress(route.query?.ft) ? this.toRawAddress(route.query?.ft) : null;
        first = mergeTokens.find((item) => item.address === ftRawAddress) ||
          mergeTokens.find((item) => item.symbol === route.query?.ft);

        if (first) {
          this.DEX_SEND_TOKEN(first);
        }

        setTimeout(() => {
          if (route.query?.fa > 0) {
            this.DEX_SEND_AMOUNT(Number(route.query?.fa));
          } else if (route.query?.sa > 0) {
            this.DEX_RECEIVE_AMOUNT(Number(route.query?.sa));
          }
        }, 10);

      } else {
        let findToken = mergeTokens.find((item) => item.type === 'native');
        if (findToken) {
          this.DEX_SEND_TOKEN(findToken);
        }
      }
    },
		async getAccountInfo(wallet) {
			try {
				let balance = await this.getBalanceWithRetry(wallet)
				let walletInfo = {
					address: wallet.address,
					balance: Number(balance),
				}
				
				let mergedTokens = await this.mergeTonTokens(walletInfo);
				this.DEX_USER_TOKENS(mergedTokens);
				return mergedTokens
			} catch (err) {
				console.error(err);
			}
		},
		async getBalanceWithRetry(wallet) {
			try {
				return await this.getBalance(wallet);
			} catch (err) {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				return await this.getBalanceFromTonApi(wallet);
			}
		},
		async getBalance(wallet) {
			try {
				let res = await tonApiService.getBalance(wallet.address);
				return res?.data;
			} catch (err) {
				throw err;
			}
		},
		async getBalanceFromTonApi(wallet) {
			try {
				let res = await tonApiService.getTonWalletInfo(wallet.address);
				return res?.balance;
			} catch (err) {
				throw err;
			}
		},
		async mergeTonTokens(walletInfo) {
			let jettons = await this.getTonJettons(walletInfo);
			let toncoin = this.GET_TON_TOKENS.find((item) => item.address === 'native');
			if (walletInfo?.balance) {
				toncoin.balance = walletInfo?.balance / Math.pow(10, toncoin?.decimals);
			}
			if (jettons.length === 0) {
				jettons.unshift(toncoin);
			} else if (jettons.length > 0 && !jettons.find((item) => item.name === toncoin.name)) {
				jettons.unshift(toncoin);
			}
			return jettons;
		},
		async getTonJettons(wallet) {
			try {
				let jettons = await tonApiService.getTonJettons(toUserFriendlyAddress(wallet.address));
				let myTokenAddresses = [];
				
				jettons?.balances.forEach((item) => {
					if (Number(item?.balance) > 0) {
						myTokenAddresses.push(item?.jetton.address);
					}
				});
				
				let array = await this.getMyTokensByAddress(myTokenAddresses, jettons);
				
				let loadedTokens = this.mergeArrays(array, this.GET_TON_TOKENS)
				
				loadedTokens.forEach((token) => {
					let findItem = array.find((find) => find.address === token.address)
					if (findItem) {
						token = findItem
					} else {
						token.balance = 0
					}
				})
				
				this.DEX_TON_TOKENS(loadedTokens);
				return array;
			} catch (err) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				return await this.getTonJettons(wallet);
			}
		},
		async getMyTokensByAddress(myTokenAddresses, jettons) {
			try {
				let array = []
				let getMyTokens = await tokenService.getTokensByAddress(myTokenAddresses)
				
				getMyTokens.forEach((item) => {
					let findItem = jettons?.balances.find((find) => find?.jetton.address === item.address)
					if (findItem) {
						array.push({...item,
							balance: Number(findItem.balance / Math.pow(10, item?.decimals)),
							labels: item.labels === null ? [] : item.labels
						})
					}
				})
				return array
			} catch(err) {
				throw err
			}
		},
		changeMetaTheme() {
			let meta = document.querySelector('meta[name="theme-color"]')
			if (meta) {
				if (this.GET_THEME === 'coffee') {
					meta.setAttribute('content', '#0A0706')
					return
				}
				if (this.GET_THEME === 'light') {
					meta.setAttribute('content', '#f8f8f8')
				}
				if (this.GET_THEME === 'dark') {
					meta.setAttribute('content', '#0A0A0A')
				}
			}
		}
	},
	mounted() {
		this.getPinnedTokens();
		this.getTokenLabels();
		
		let tonConnectStorage = JSON.parse(localStorage.getItem('ton-connect-storage_bridge-connection'));
		let walletInfoStorage = JSON.parse(localStorage.getItem('ton-connect-ui_wallet-info'));
		let wallet = tonConnectStorage?.connectEvent?.payload?.items[0];
		let proof = JSON.parse(localStorage.getItem('tonProof_ver'));
		
		if (wallet) {
			wallet.userFriendlyAddress = toUserFriendlyAddress(wallet?.address);
			wallet.imgUrl = walletInfoStorage?.imageUrl;
			if (tonConnectStorage) {
				this.DEX_WALLET(wallet);
			}
		}
		if (proof) {
			this.DEX_PROOF_VERIFICATION(proof);
		}
		
		this.loadTokensFromQueryParams();
		
		setTimeout(() => {
			if (this.GET_DEX_WALLET === null) {
				this.getTonTokens();
				if (!this.GET_DEX_WALLET?.address) {
					if (isInsideWalletBrowser("tonkeeper")) {
						this.tonConnectUi.openModal();
					}
				}
			}
		}, 1000);
	},
	unmounted() {
		if (this.unsubscribeConnect !== null) {
			this.unsubscribeConnect()
		}
		this.CLEAR_DEX_STORE()
		this.CLEAR_DEX_SETTINGS()
	},
	watch: {
		GET_DEX_WALLET: {
			handler() {
				let tonConnectStorage = JSON.parse(localStorage.getItem('ton-connect-storage_bridge-connection'))
				if (this.GET_DEX_WALLET !== null) {
					if (this.loadInfoCount === 0) {
						this.getTonTokens()
					}
					if (tonConnectStorage) {
						this.loadInfoCount++

            tracking.trackEvent(Events.CONNECT_WALLET, {
              walletAddress: this.GET_DEX_WALLET?.address,
              tonConnectStorageData: tonConnectStorage
            });
					}
				} else {
					this.loadInfoCount = 0
				}
			}
		},
		GET_THEME: {
			handler() {
				this.changeMetaTheme()
			}
		},
    $route(to, from) {
      if (to.name === 'Stake') {
        let tokens = this.GET_TON_TOKENS;
        this.checkQueryParams(tokens);
      }

      if (to.name === 'Dex') {
        let tokens = this.GET_TON_TOKENS;
        this.checkQueryParams(tokens);
      }

      if (from.name === 'Stake' && to.name === 'Dex') {
        let tokens = this.GET_TON_TOKENS;
        this.checkQueryParams(tokens);
      }
    },
		getRouteName: {
			handler() {
				clearTimeout(this.timeout)
				this.ROUTE_CHANGED(true)
				this.timeout = setTimeout(() => {
					this.ROUTE_CHANGED(false)
				}, 300)
			}
		}
	}
}
</script>

<style scoped>
.app-wrapper {
  margin-top: 68px;
  overflow: hidden;
  background: var(--main-bg-color);
  height: 100%;
  min-height: calc(100dvh - 68px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-wrapper__flex {
	width: calc(100% + 268px);
	display: flex;
}

@media screen and (max-width: 1180px) {
  .app-wrapper {
    max-height: 100%;
    overflow: auto;
  }

  .app-wrapper__flex {
    width: 100%;
  }

  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .app-wrapper {
    margin-top: 64px;
    min-height: calc(100dvh - 64px);
  }

  .dex_wrapper {
    display: block;
  }
}
</style>
