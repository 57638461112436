import {THEME, toUserFriendlyAddress} from '@tonconnect/ui';
import {mapActions, mapGetters} from 'vuex';
import { profileService, geoService } from '@/api/coffeeApi/services';
import { isInsideWalletBrowser } from '@/helpers/dex/embedded-wallets.js';

export function generatePayload() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default {
    computed: {
        ...mapGetters([
            'GET_PROOF_VERIFICATION',
            'GET_SLIPPAGE',
            'GET_EXPERT_MODE_VALUE',
            'GET_PRICE_IMPACT',
            'GET_MAX_POOL_VOLATILITY',
            'GET_MAX_INTERMEDIATE_TOKENS',
            'GET_MAX_SPLITS',
            'GET_THEME',
            'GET_CHART_VISIBLE_SETTING'
        ]),
        tonConnectSettings() {
            return {
                manifestUrl: 'https://swap.coffee/tonconnect-manifest.json',
                uiPreferences: {
                    theme: THEME.DARK,
                },
            };
        },
    },
    methods: {
        ...mapActions(['SAVE_USER_SETTINGS']),
        async restoreUiConnection() {
            try {
                const restored = await this.tonConnectUi.connectionRestored;

                if (!restored) {
                    console.log('Connection was not restored.');
                    return;
                }

                console.log('Connection restored');
                const account = this.prepareAccountData(this.tonConnectUi.account, this.tonConnectUi.walletInfo);

                const proof = this.getProof(account.userFriendlyAddress);
                if (proof) {
                    this.executeProofFlow(account, proof);
                } else {
                    await this.disconnectWallet(account.userFriendlyAddress);
                }
            } catch (error) {
                console.error(error);
            }
        },
        prepareAccountData(account, walletInfo) {
            return {
                ...account,
                userFriendlyAddress: toUserFriendlyAddress(account.address),
                imgUrl: walletInfo?.imageUrl || ''
            };
        },
        getProof(address) {
            const proofKey = isInsideWalletBrowser("tonkeeper")
              ? `tonProof_ver_${address}`
              : 'tonProof_ver';

            return JSON.parse(localStorage.getItem(proofKey));
        },
        executeProofFlow(account, proof) {
            this.DEX_WALLET(account);
            this.getContractVersion(account.userFriendlyAddress);
            this.DEX_PROOF_VERIFICATION(proof);
            this.getUserSettings();
            this.bindReferralLink();
            this.getReferralInfo();
        },
        async disconnectWallet(userFriendlyAddress) {
            try {
                if (this.tonConnectUi.wallet !== null) {
                    await this.tonConnectUi.disconnect();
                    this.DEX_WALLET(null);
                }

                if (isInsideWalletBrowser("tonkeeper")) {
                    if (userFriendlyAddress) {
                        localStorage.removeItem(`tonProof_ver_${userFriendlyAddress}`);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        tonproofSetConnect() {
            if (isInsideWalletBrowser("tonkeeper")) {
                const account = this.tonConnectUi.account;
                const proofKey = account ? `tonProof_ver_${toUserFriendlyAddress(account.address)}` : null;

                if (proofKey && localStorage.getItem(proofKey)) {
                    return;
                }
            } else {
                const storedVerification = JSON.parse(localStorage.getItem('tonProof_ver'));
                if (storedVerification) {
                    return;
                }
            }

            this.tonConnectUi.setConnectRequestParameters({
                state: "ready",
                value: { tonProof: generatePayload() }
            });
        },
        async getUserSettings() {
            try {
                let settings = await profileService.readStorage(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION)
                if (settings?.data?.dexSettings && settings?.data?.globalSettings) {
                    this.SAVE_USER_SETTINGS(settings?.data)
                } else {
                    await this.setDefaultSettings()
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    this.disconnectWallet()
                }
                console.error(err)
            }
        },
        async setDefaultSettings() {
            try {
                const userCountryCode = await geoService.getUserCountryCode();
                let newSettings;

                if (userCountryCode) {
                    this.$i18n.locale = this.getLocaleForCountry(userCountryCode);
                    newSettings = {
                        theme: this.GET_THEME || 'dark',
                        lang: userCountryCode.toLowerCase(),
                        chartVisible: this.GET_CHART_VISIBLE_SETTING || true
                    };
                    localStorage.setItem('globalSettings', JSON.stringify(newSettings));
                }

                let dex = {
                    slippage: this.GET_SLIPPAGE,
                    expertMode: this.GET_EXPERT_MODE_VALUE,
                    priceImpact: this.GET_PRICE_IMPACT,
                    maxPoolVolatility: this.GET_MAX_POOL_VOLATILITY,
                    maxIntermediateTokens: this.GET_MAX_INTERMEDIATE_TOKENS,
                    maxSplits: this.GET_MAX_SPLITS,
                };

                await profileService.writeStorage(
                    this.GET_DEX_WALLET?.address,
                    this.GET_PROOF_VERIFICATION,
                    {
                        globalSettings: newSettings,
                        dexSettings: dex,
                    },
                );
            } catch (err) {
                console.error(err);
            }
        },
    },
};
